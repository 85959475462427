import React from 'react'

import { Layout } from '../components'

const NotFoundPage = () => {
  return (
    <Layout title='Page Not Found'>
      <h1>Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export default NotFoundPage
